/**
 * T REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: it_helpdesk@iru.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeclarationMessageStatus = 'send' | 'send_registration' | 'error' | 'mrn_all' | 'officer_control' | 'vehicle_released' | 'vehicle_arrived' | 'not_released' | 'guarantee_not_valid' | 'guarantee_approve' | 'discrepancies' | 'declaration_closed' | 'declaration_rejected' | 'declaration_invalidated' | 'tad';

export const DeclarationMessageStatus = {
    Send: 'send' as DeclarationMessageStatus,
    SendRegistration: 'send_registration' as DeclarationMessageStatus,
    Error: 'error' as DeclarationMessageStatus,
    MrnAll: 'mrn_all' as DeclarationMessageStatus,
    OfficerControl: 'officer_control' as DeclarationMessageStatus,
    VehicleReleased: 'vehicle_released' as DeclarationMessageStatus,
    VehicleArrived: 'vehicle_arrived' as DeclarationMessageStatus,
    NotReleased: 'not_released' as DeclarationMessageStatus,
    GuaranteeNotValid: 'guarantee_not_valid' as DeclarationMessageStatus,
    GuaranteeApprove: 'guarantee_approve' as DeclarationMessageStatus,
    Discrepancies: 'discrepancies' as DeclarationMessageStatus,
    DeclarationClosed: 'declaration_closed' as DeclarationMessageStatus,
    DeclarationRejected: 'declaration_rejected' as DeclarationMessageStatus,
    DeclarationInvalidated: 'declaration_invalidated' as DeclarationMessageStatus,
    Tad: 'tad' as DeclarationMessageStatus
};

