import { Injectable, NgZone } from "@angular/core";
import { WebApplicationConfigurationService } from "@app/shared/services/web-application-configuration.service";
import posthog from "posthog-js";
import { forkJoin } from "rxjs";
import StackTracey from "stacktracey";
import { Router, NavigationEnd } from "@angular/router";

class EpdStackTracey extends StackTracey {

  isThirdParty(relativePath: string): boolean {   // you can use externalDomain to include traces from libs from other domains
    const thirdParty = super.isThirdParty(relativePath);
    console.log(`isThirdParty(${relativePath})=${thirdParty}`);
    return (thirdParty    // include default behavior
            || relativePath.includes ('my-lib'))     // paths including 'my-lib' will be marked as thirdParty provided as example
  }
}

@Injectable({
  providedIn: 'root'
})
export class PosthogService {
  private _initialized: boolean = false;
  private _errorRecording: boolean = false;

  constructor(
    private webApplicationConfigurationService: WebApplicationConfigurationService,
    private zone: NgZone) {
  }

  public init(): void {
    // initialise PostHog Analytics with environment configuration
    forkJoin([
      this.webApplicationConfigurationService.getPublicPropertyValue('public.analytics.posthog.project.api.key'),
      this.webApplicationConfigurationService.getPublicPropertyValue('public.analytics.posthog.instance.url'),
      this.webApplicationConfigurationService.getPublicPropertyValue('public.analytics.posthog.init.additional.attributes')
    ])
      .subscribe(async ([analyticsPosthogProjectApiKey, analyticsPosthogInstanceUrl, analyticsPosthogInitAdditionalAttributes]) => {
        if (analyticsPosthogProjectApiKey && analyticsPosthogInstanceUrl) {
          const basicProps = { api_host: analyticsPosthogInstanceUrl };
          let properties = basicProps;
          if (analyticsPosthogInitAdditionalAttributes) {
            properties = Object.assign(basicProps, JSON.parse(analyticsPosthogInitAdditionalAttributes));
          }
          posthog.init(analyticsPosthogProjectApiKey, properties);
          posthog.onFeatureFlags(this.toggleSessionRecording.bind(this));
          posthog.onFeatureFlags(this.toggleErrorRecording.bind(this));
          this._initialized = true;
        }
      });
  }

  public identify(username: string, tenant: string, bctIndicator: boolean): void {
    if (this._initialized) {
      posthog.identify(username, { 'username': username, 'tenant': tenant, 'bctIndicator': bctIndicator });
    }
  }

  public capture(router: Router): void {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this._initialized) {
          posthog.capture('$pageview');
        }
      }
    });
  }

  private toggleSessionRecording(): void {
    if (posthog.isFeatureEnabled('record-session')) {
      this.zone.runOutsideAngular(() => {
        posthog.startSessionRecording();
      });
    } else {
      this.zone.runOutsideAngular(() => {
        posthog.stopSessionRecording();
      });
    }
  }

  private toggleErrorRecording(): void {
    this._errorRecording = posthog.isFeatureEnabled('record-error');
  }

  public async captureError(error: any): Promise<void> {
    if (this._errorRecording) {
      const tracey = new EpdStackTracey(error);
      let stack = await tracey.withSourcesAsync ();
      stack = await stack.cleanAsync();
      const prettyPrintedString: string = stack.asTable({maxColumnWidths: {
          callee:     100,
          file:       100,
          sourceLine: 100
      }});
      const stacktrace: string = `${error.message}:\n${prettyPrintedString}`;
      if (stack.items.length !== 1 && !stack.items[0].thirdParty) {
        console.log(stacktrace);
        posthog.capture('Error', { stacktrace });
      } else {
        console.log(`skip ${stacktrace}`);
      }
    }
  }

  public reset(): void {
    if (this._initialized) {
      posthog.reset();
    }
  }
}
