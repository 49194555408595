import {
    HttpErrorResponse, HttpEvent, HttpHandler,

    HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { HttpError } from './http-error-codes';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private errorService: ErrorService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                tap((event: HttpEvent<any>) => { }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        switch (err.status) {
                            case HttpError.UnavailableForLegalReasons:
                            case HttpError.BadRequest:
                            case HttpError.Gone:
                            case HttpError.NotFound:
                                // BadRequest, Gone and NotFound are the responsability of the component to deal with
                                break;
                            case HttpError.Conflict:
                                this.errorService.errors = ['http.conflict.error'];
                                break;

                            default:

                                // We display a standard error message, use err.message to get the real message.
                                this.errorService.errors = ['technical.error.has.occured'];
                                break;
                        }
                    }
                })
            );
    }
}
